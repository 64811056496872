/**
 * External Dependencies
 */
import React from "react"
import classNames from "classnames"

/**
 * Internal Dependencies
 */
import CustomReveal from "./CustomReveal"
import BackgroundWordpressImage from "./BackgroundWordpressImage"

export default function FullscreenPanel({
  backgroundColor,
  backgroundColorSlug,
  children,
  imgDesktop,
  imgMobile,
  overlay,
  video,
}) {
  return (
    <BackgroundWordpressImage
      className={classNames(
        "component",
        "component-fullscreen-panel",
        "alignfull",
        `bg-${backgroundColorSlug}`
      )}
      backgroundColor={backgroundColor}
      src={imgDesktop}
      mobileSrc={imgMobile}
      style={{
        backgroundColor: backgroundColor || "transparent",
      }}
    >
      {!!video?.length && (
        <video
          className="background-video"
          autoPlay
          playsInline
          loop
          muted
          src={video}
        />
      )}
      {!!overlay && <div className="overlay" />}
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <CustomReveal effect="fadeInUp">
              <div>{children}</div>
            </CustomReveal>
          </div>
        </div>
      </div>
    </BackgroundWordpressImage>
  )
}
