/**
 * External Dependencies
 */
import React from 'react'
import classNames from 'classnames'

export default function GridLayout({
  layout,
  reverseStackMobile,
  col1InnerContent,
  col2InnerContent,
}) {
  /**
   * Two layouts only have 1 column:
   * - "Wide", with a 1-10-1 column layout
   * - "Wider", with a 12 column layout
   * Handling these isn't pretty - they were added late into dev
   */
  const hasSecondColumn = layout !== '1-10-1' && layout !== '12'

  // values from data attributes come in as strings so we have to compare strings
  const mobileStackOrder = reverseStackMobile === 'true' ? 'reverse' : 'default'

  return (
    <div className="component component-grid-layout alignfull">
      <div className="container">
        <div className="row">
          <div
            className={classNames({
              'component-grid-layout-column': true,
              'col-md-6': layout === '6-6',
              'col-md-8': layout === '8-4',
              'col-md-4': layout === '4-8',
              'col-md-10 offset-md-1': layout === '1-10-1',
              'col-md-12': layout === '12',
              'order-md-1 order-1': mobileStackOrder === 'default',
              'order-md-1 order-2': mobileStackOrder === 'reverse',
            })}
          >
            {col1InnerContent}
          </div>
          {!!hasSecondColumn && (
            <div
              className={classNames({
                'component-grid-layout-column': true,
                'col-md-6': layout === '6-6',
                'col-md-4': layout === '8-4',
                'col-md-8': layout === '4-8',
                'order-md-1 order-2': mobileStackOrder === 'default',
                'order-md-1 order-1': mobileStackOrder === 'reverse',
              })}
            >
              {col2InnerContent}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
