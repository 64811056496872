/* eslint-disable react-hooks/exhaustive-deps */
/**
 * External Dependencies
 */
import React, { useEffect, useRef, createRef } from "react"
import classNames from "classnames"
import gsap from "gsap"
import shortid from "shortid"
import { ScrollTrigger } from "gsap/ScrollTrigger"
// import { CustomEase } from "gsap/CustomEase";

/**
 * Internal Dependencies
 */
import WordpressImage from "./WordpressImage"

export default function Stack({
  backgroundColor,
  backgroundColorSlug,
  items,
  title,
  textColor,
  additionalClassNames,
}) {
  const stackRef = useRef(null)
  const timelineRef = useRef(null)
  const mediaRefs = items.map(item => createRef())
  const captionRefs = items.map(item => createRef())
  const t1 = useRef()
  const t2 = useRef()

  // console.log("stackRef", stackRef)
  // console.log("mediaRefs", mediaRefs)
  // console.log("captionRefs", mediaRefs)

  const Timeline = ({ children }) => {
    useEffect(() => {
      if (stackRef.current == null) return
      // if (mediaRefs.current == null) return
      // if (captionRefs.current == null) return

      if (typeof window !== `undefined`) {
        gsap.registerPlugin(ScrollTrigger)
        gsap.core.globals("ScrollTrigger", ScrollTrigger)
        ScrollTrigger.saveStyles(".mobile, .desktop")
      }

      ScrollTrigger.matchMedia({
        // Only for tablet & larger
        // "(min-width: 768px)": function () {
        // eslint-disable-next-line func-names
        "(min-width: 0px)": function () {
          if ([...mediaRefs, ...captionRefs].includes(null)) {
            return
          }

          const scrollTriggerOptions = {
            trigger: stackRef.current,
            start: "top top",
            end: `+=${items.length - 2}00%`,
            pin: true,
            pinSpacing: true,
            scrub: 0.25,
            id: "stack",
            // markers: true,
            // snap: {
            //   snapTo: [0, 2, 4, 6],
            // },
          }

          t1.current = gsap.timeline({
            scrollTrigger: scrollTriggerOptions,
          })

          t2.current = gsap.timeline({
            scrollTrigger: scrollTriggerOptions,
          })

          for (let i = 0; i < items.length - 1; i += 1) {
            t1.current.add(`media[${i + 1}]`).to(mediaRefs[i].current, {
              y: "-=100vh",
            })
            t2.current.add(`caption[${i + 1}]`).to(captionRefs[i].current, {
              // ease: CustomEase.create("rapid", "M0,0 C0.266,0.412 0.044,1 0.274,1 0.274,1 0.78,1 1,1 "),
              ease: "power4.out",
              autoAlpha: 0,
            })
          }
        },
      })

      // Now make the component kill the gsap instance when unmounted
      // https://greensock.com/forums/topic/25526-react-passing-array-of-refs-to-timeline/
      return () => {
        t1?.current?.kill()
        t2?.current?.kill()
      }
    }, [stackRef, mediaRefs, captionRefs, t1, t2])

    return <div ref={timelineRef}>{children}</div>
  }

  const centerStack = additionalClassNames.includes('center-stack')

  const itemsAsHTML = items.map((item, index) => {
    const id = shortid.generate()

    const zIndex = items.length - 1 - index

    return (
      <div className="stack-item" key={id}>
        <div
          className="row w-100"
          style={{
            zIndex,
          }}
        >
          <div
            className={classNames(
              "col-10",
              (centerStack) ? "offset-1" : "offset-2",
              (centerStack) ? "col-md-6" : "col-md-5",
              (centerStack) ? "offset-md-3" : "offset-md-2",
            )}
          >
            <div className="stack-item-img-wrapper" ref={mediaRefs[index]}>
              <div
                className={classNames(
                  "stack-item-img",
                  // `stack-item-img--layer-${zIndex}`
                  (centerStack) ? 'stack-item-img--layer-center' : `stack-item-img--layer-${zIndex}`
                )}
              >
                <WordpressImage
                  preventReveal
                  src={item.imageUrl}
                  alt={item.imageAlt}
                />
              </div>
            </div>
          </div>
          { !centerStack && (
            <div
              className={classNames(
                "stack-item-caption",
                "col-10 offset-1",
                "col-md-4",
                "offset-md-1",
                `bg-${item.backgroundColorSlug}`
              )}
              ref={captionRefs[index]}
            >
              {item.innerContent}
            </div>
          )}
        </div>
      </div>
    )
  })

  return (
    <div>
    <div
      ref={stackRef}
      className={classNames(
        "component",
        "component-stack",
        "alignfull",
        `bg-${backgroundColorSlug}`,
        additionalClassNames
      )}
      style={{
        backgroundColor: backgroundColor || "transparent",
        color: textColor || "inherit",
      }}
    >
      <div className="stack-items alignfull">
        <div className="container position-relative">
          <div className="row">
            <div
              className={classNames(
                "col-12",
                (centerStack) ? "col-lg-6" : "col-lg-5",
                (centerStack) ? "offset-lg-3" : "offset-lg-6",
                (centerStack) ? "col-md-6" : "col-md-7",
                (centerStack) ? "offset-md-3" : "offset-md-5",
              )}
            >
              { title && (<h3 className="stack-items-title">{ title }</h3>) }
            </div>
          </div>

          <Timeline>{itemsAsHTML}</Timeline>
        </div>
      </div>
    </div>
    </div>
  )
}
