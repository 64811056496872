/**
 * External Dependencies
 */
import React from "react"
import { domToReact } from "html-react-parser"

/**
 * Internal Dependencies
 */
import { colorToSlug } from "../colors"
import Carousel from "../../components/Carousel"

export const isCarousel = domNode =>
  domNode.name === "div" &&
  domNode.attribs?.class?.startsWith("wp-block-mazda-stories-carousel")

export const parseCarousel = (domNode, options) => {
  const items = domNode.children.reduce((acc, curr) => {
    if (
      curr.name === "div" &&
      curr.attribs?.class?.startsWith("wp-block-mazda-stories-carousel-item")
    ) {
      acc.push({
        imageUrl: curr.attribs["data-image-url"],
        imageId: curr.attribs["data-image-id"],
        lottieUrl: curr.attribs["data-lottie-url"],
        stat: domToReact([curr.children?.[1]]),
        headline: domToReact([curr.children?.[3]]),
        subheadline: domToReact([curr.children?.[5]]),
        body: domToReact([curr.children?.[7]]),
      })
    }
    return acc
  }, [])

  return (
    <Carousel
      backgroundColor={domNode.attribs["data-background-color"]}
      backgroundColorSlug={colorToSlug(
        domNode.attribs["data-background-color"]
      )}
      slidesPerView={domNode.attribs["data-slides-per-view"]}
      textColor={domNode.attribs["data-text-color"]}
      title={domNode.attribs["data-headline"]}
      items={items}
    />
  )
}
