/**
 * External Dependencies
 */
import React, { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

export default ({ children, className, backgroundColor, src, mobileSrc, style }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { internal: { mediaType: { regex: "^/image/" } } }) {
        edges {
          node {
            publicURL
            childImageSharp {
              fluid(quality: 90, maxWidth: 1024) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const match = useMemo(
    () => data.allFile.edges.find(({ node }) => src === node.publicURL),
    [data, src]
  )

  if (!match) {
    return (
      <div className={className} style={style}>
        {children}
      </div>
    )
  }

  const mobileMatch = useMemo(
    () => data.allFile.edges.find(({ node }) => mobileSrc === node.publicURL),
    [data, mobileSrc]
  )

  let fluidSrc = match?.node?.childImageSharp?.fluid

  if (!mobileMatch) {

  } else {
    // has mobile match
    fluidSrc = [
      mobileMatch?.node?.childImageSharp?.fluid,
      {
        ...match?.node?.childImageSharp?.fluid,
        media: '(min-width: 625px)',
      }
    ]
  }

  return (
    <BackgroundImage
      Tag="div"
      className={className}
      fluid={fluidSrc}
      backgroundColor={backgroundColor}
      style={style}
      loading="eager"
    >
      {children}
    </BackgroundImage>
  )
}
