/**
 * External Dependencies
 */
import React from "react"
import classNames from "classnames"

/**
 * Internal Dependencies
 */
import Animation from "./Animation"
import BackgroundWordpressImage from "./BackgroundWordpressImage"

export default function ArticleHeader({
  backgroundColor,
  backgroundColorSlug,
  button,
  overlay,
  overline,
  headline,
  subheadline,
  imgMobile,
  imgDesktop,
  video,
  additionalClassNames,
}) {
  const hasMedia = imgDesktop?.length || video?.length
  const extraClasses = (additionalClassNames !== undefined) ? additionalClassNames.trim() : ''

  return (
    <BackgroundWordpressImage
      className={classNames(
        "component component-article-header alignfull",
        `bg-${backgroundColorSlug}`,
        extraClasses,
        {
          "has-media": hasMedia,
        }
      )}
      src={imgDesktop}
      mobileSrc={imgMobile}
      backgroundColor={backgroundColor}
      style={{
        backgroundColor: backgroundColor || "transparent",
        width: "100vw",
      }}
    >
      {!!video?.length && (
        <video
          className="background-video"
          autoPlay
          playsInline
          loop
          muted
          src={video}
        />
      )}
      {!!overlay && <div className="overlay h-400" />}
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div className="overline">{overline}</div>
            <div className="headline">{headline}</div>
          </div>
        </div>
        {!!subheadline && (
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="subheadline">{subheadline}</div>
            </div>
          </div>
        )}
        {!!button && (
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="button">{button}</div>
            </div>
          </div>
        )}
        {!!hasMedia && (
          <div className="component-article-header--scroller">
            <Animation path="https://assets2.lottiefiles.com/packages/lf20_g83xxups.json" />
          </div>
        )}
      </div>
    </BackgroundWordpressImage>
  )
}
