/**
 * External Dependencies
 */
import React from 'react'

/**
 * Internal Dependencies
 */
import WordpressImage from './WordpressImage'

export default function OverlappingColumns({ headline, imageSmallUrl, imageLargeUrl, bodyText }) {
  return (
    <div className="component component-overlapping-columns alignfull">
      <div className="container">
        {!!headline && (
          <div className="row">
            <div data-testid="headline" className="col-md-8 offset-md-2">
              {headline}
            </div>
          </div>
        )}
        {!!(imageSmallUrl && imageLargeUrl) && (
          <div className="row">
            <div className="col-6 col-md-3 offset-md-2 col-lg-4 offset-lg-1 component-overlapping-columns--col-sm">
              <WordpressImage effect="fadeInUp" src={imageSmallUrl} />
            </div>
            <div className="col-8 offset-4 col-md-6 offset-md-4 component-overlapping-columns--col-lg">
              <WordpressImage effect="fadeIn" src={imageLargeUrl} />
            </div>
          </div>
        )}
        {!!bodyText && (
          <div className="row">
            <div data-testid="body-text" className="col-md-4 offset-md-6">
              {bodyText}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
