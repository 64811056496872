/**
 * External Dependencies
 */
import React from "react"
import { domToReact } from "html-react-parser"

/**
 * Internal Dependencies
 */
import { colorToSlug } from "../colors"
import CTA from "../../components/CTA"

export const isCTA = domNode =>
  domNode.name === "div" &&
  domNode.attribs?.class?.startsWith("wp-block-mazda-stories-cta")

export const parseCTA = (domNode, options) => (
  <CTA
    align={domNode.attribs["data-align"]}
    background={domNode.attribs["data-background"]}
    backgroundColor={domNode.attribs["data-background-color"]}
    backgroundColorSlug={colorToSlug(domNode.attribs["data-background-color"])}
    imgDesktop={domNode.attribs["data-image-url"]}
    imgMobile={domNode.attribs["data-mobile-image-url"]}
    textColor={domNode.attribs["data-text-color"]}
    video={domNode.attribs["data-video"]}
    overlay={domNode.attribs["data-overlay"] === "true"}
    overline={domToReact([domNode.children?.[1]])}
    headline={domToReact([domNode.children?.[3]])}
    subheadline={domToReact([domNode.children?.[5]])}
    button={domToReact([domNode.children?.[7]])}
    additionalClassNames={domNode.attribs["class"].replace('wp-block-mazda-stories-cta alignfull', '')}
  />
)
