/**
 * External Dependencies
 */
import React from "react"
import { domToReact } from "html-react-parser"

/**
 * Internal Dependencies
 */
import GridLayout from "../../components/GridLayout"

export const isGridLayout = domNode =>
  domNode.name === "div" &&
  domNode.attribs?.class?.startsWith("wp-block-mazda-stories-grid-layout")

export const parseGridLayout = (domNode, options) => (
  <GridLayout
    align={domNode.attribs["data-align"]}
    layout={domNode.attribs["data-layout"]}
    reverseStackMobile={domNode.attribs["data-reverse-stack-mobile"]}
    col1InnerContent={domToReact(domNode.children?.[1]?.children)}
    col2InnerContent={domToReact(domNode.children?.[3]?.children)}
  />
)
