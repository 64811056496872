/**
 * External Dependencies
 */
import React from "react"
import { domToReact } from "html-react-parser"

/**
 * Internal Dependencies
 */
import { colorToSlug } from "../colors"
import FullscreenPanel from "../../components/FullscreenPanel"

export const isFullscreenPanel = domNode =>
  domNode.name === "div" &&
  domNode.attribs?.class?.startsWith("wp-block-mazda-stories-fullscreen-panel")

export const parseFullscreenPanel = (domNode, options) => (
  <FullscreenPanel
    background={domNode.attribs["data-background"]}
    backgroundColor={domNode.attribs["data-background-color"]}
    backgroundColorSlug={colorToSlug(domNode.attribs["data-background-color"])}
    imgDesktop={domNode.attribs["data-image-url"]}
    imgMobile={domNode.attribs["data-mobile-image-url"]}
    overlay={domNode.attribs["data-overlay"] === "true"}
    video={domNode.attribs["data-video"]}
  >
    {domToReact(domNode.children)}
  </FullscreenPanel>
)
