/**
 * External Dependencies
 */
import React from "react"
import Reveal from "react-reveal/Reveal"

export default ({ children, effect }) => (
  <Reveal effect={effect} fraction={0.01}>
    {children}
  </Reveal>
)
