/* eslint-disable no-shadow */
/**
 * External Dependencies
 */
import React, { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/**
 * Internal Dependencies
 */
import CustomReveal from "./CustomReveal"

export default ({ className, effect, preventReveal, src, mobileSrc }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { internal: { mediaType: { regex: "^/image/" } } }) {
        edges {
          node {
            publicURL,
            url,
            childImageSharp {
              fluid(quality: 90, maxWidth: 1024) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  /* eslint-disable prettier/prettier */
  const match = useMemo(() => (
    data.allFile.edges.find(({ node }) => src === node.publicURL)
  ), [ data, src ])
  /* eslint-enable prettier/prettier */

  if (!match) {
    return <></>
  }

  // fudge to let us display gifs in a WordpressImage component
  // as GatsbyImage doesn't like gifs
  let img = (
    <img
      src={match.node.publicURL}
      // put a width=100% to make sure the gif resizes to container
      style={{width: '100%'}}
    />
  )

  let fluidSrc = match?.node?.childImageSharp?.fluid

  if (mobileSrc) {
    const mobileMatch = useMemo(
      () => data.allFile.edges.find(({ node }) => mobileSrc === node.publicURL || (node.url && node.url.includes(mobileSrc))),
      [data, mobileSrc]
    )
    if (!mobileMatch) {

    } else {
      // has mobile match
      fluidSrc = [
        mobileMatch?.node?.childImageSharp?.fluid,
        {
          ...match?.node?.childImageSharp?.fluid,
          media: '(min-width: 625px)',
        }
      ]
    }
  }

  // if fluid exists then use GatsbyImage
  if (fluidSrc) {
    img = (
      <Img
        fluid={fluidSrc}
        className={className}
        loading="eager"
      />
    )
  }

  if (preventReveal) {
    return img
  }

  return <CustomReveal effect={effect}>{img}</CustomReveal>
}
