/**
 * External Dependencies
 */
import React from "react"
import classNames from "classnames"
import { renderToString } from 'react-dom/server'

/**
 * Internal Dependencies
 */
import CustomReveal from "./CustomReveal"
import BackgroundWordpressImage from "./BackgroundWordpressImage"

export default function CTA({
  additionalClassNames,
  align,
  backgroundColor,
  backgroundColorSlug,
  button,
  overlay,
  overline,
  headline,
  subheadline,
  imgMobile,
  imgDesktop,
  textColor,
  video,
}) {

  // Total hack to get the typeform popup happening in the CTA
  const allClassNames = additionalClassNames.split(' ');
  const typeformForm = allClassNames.filter(item => {
    return item.includes('data-tf-popup')
  })

  let typeformBits = []
  if (typeformForm.length) {
    typeformBits = typeformForm[0].split('--')
  }

  const typeformId = (typeformBits[1]) ? typeformBits[1] : ''
  const typeformAttribute = (typeformBits[0]) ? typeformBits[0] : ''
  let typeformButton = ''

  if (typeformId && !!button) {
    typeformButton = renderToString(button)

    const stringToReplace = 'class="wp-block-button__link'
    const replacementString = `${typeformAttribute}="${typeformId}" class="wp-block-button__link`
    typeformButton = typeformButton.replace(stringToReplace, replacementString)
  }
  // End hack to get the typeform popup happening in the CTA

  const contents = (
    <>
      {!!overline && <CustomReveal effect="fadeInUp">{overline}</CustomReveal>}
      {!!headline && <CustomReveal effect="fadeInUp">{headline}</CustomReveal>}
      {!!subheadline && (
        <CustomReveal effect="fadeInUp">{subheadline}</CustomReveal>
      )}
      {(!!button && !typeformButton)&& button}
      {/* Now chuck the typeform hack in!  */}
      {(!!typeformButton)&& <div dangerouslySetInnerHTML={{ __html: typeformButton }}></div>}
    </>
  )

  return (
    <BackgroundWordpressImage
      className={classNames("component", "component-cta", "alignfull", {
        "component-cta--wide": align === "full",
        "has-media": imgDesktop || video,
      })}
      src={imgDesktop}
      mobileSrc={imgMobile}
      backgroundColor={backgroundColor}
      align={align}
      style={{
        color: textColor,
      }}
    >
      {!!video?.length && (
        <video
          className="background-video"
          autoPlay
          playsInline
          loop
          muted
          src={video}
        />
      )}
      {align === "full" && (
        <>
          {!!overlay && <div className="overlay" />}
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="component-cta--contents">{contents}</div>
              </div>
            </div>
          </div>
        </>
      )}
      {align !== "full" && (
        <div className="container">
          <div className="row">
            <div
              className={classNames(
                "col-md-8",
                "offset-md-2",
                `bg-${backgroundColorSlug}`
              )}
            >
              {!!overlay && <div className="overlay h-400" />}
              <div className="component-cta--contents">{contents}</div>
            </div>
          </div>
        </div>
      )}
    </BackgroundWordpressImage>
  )
}
