/**
 * External Dependencies
 */
import React from "react"
import { domToReact } from "html-react-parser"

/**
 * Internal Dependencies
 */
import { colorToSlug } from "../colors"
import Stack from "../../components/Stack"

export const isStack = domNode =>
  domNode.name === "div" &&
  domNode.attribs?.class?.startsWith("wp-block-mazda-stories-stack")

export const parseStack = (domNode, options) => {
  const items = domNode.children.reduce((acc, curr, currIndex) => {
    if (
      curr.name === "div" &&
      curr.attribs?.class?.startsWith("wp-block-mazda-stories-stack-item")
    ) {
      acc.push({
        backgroundColor: domNode.attribs["data-background-color"],
        backgroundColorSlug: colorToSlug(
          domNode.attribs["data-background-color"]
        ),
        imageUrl: curr.attribs["data-image-url"],
        imageId: curr.attribs["data-image-id"],
        innerContent: domToReact([curr.children?.[1]?.children?.[1]]),
        textColor: curr.attribs["data-text-color"],
      })
    }
    return acc
  }, [])

  return (
    <Stack
      items={items}
      backgroundColor={domNode.attribs["data-background-color"]}
      backgroundColorSlug={colorToSlug(
        domNode.attribs["data-background-color"]
      )}
      title={domNode.attribs["data-headline"]}
      textColor={domNode.attribs["data-text-color"]}
      additionalClassNames={domNode.attribs["class"].replace('wp-block-mazda-stories-stack', '')}
    />
  )
}
