/**
 * External Dependencies
 */
import React, { useRef } from "react"
import shortid from "shortid"
import classNames from "classnames"
import { Swiper, SwiperSlide } from "swiper/react"

/**
 * Internal Dependencies
 */
import Animation from "./Animation"
import BackgroundWordpressImage from "./BackgroundWordpressImage"
import SwiperNavButtons from "./SwiperNavButtons"
import WordpressImage from "./WordpressImage"

export default function Carousel({
  backgroundColor,
  backgroundColorSlug,
  items,
  title,
  slidesPerView,
  textColor,
}) {
  if (!items) {
    return <></>
  }

  const slides = items.map(item => {
    const slideFooter = (
      <div className="text-center">
        {!!item.headline && item.headline}
        {!!item.subheadline && item.subheadline}
        {!!item.body && item.body}
      </div>
    )
    // 1 Slide-Per-View is "full-width" items, with no Stat
    if (parseInt(slidesPerView, 10) === 1) {
      let image

      if (item.lottieUrl) {
        image = <Animation path={item.lottieUrl} />
      } else {
        image = <WordpressImage preventReveal src={item.imageUrl} />
      }

      return (
        <SwiperSlide key={shortid.generate()}>
          <div className="component-carousel-image">{image}</div>
          <div className="row">
            <div className="col-md-6 offset-md-3">{slideFooter}</div>
          </div>
        </SwiperSlide>
      )
    }

    let backgroundImage

    if (item.lottieUrl) {
      backgroundImage = (
        <>
          <div className="component-carousel--item--background-animation">
            <Animation path={item.lottieUrl} />
          </div>
          <div className="component-carousel--item--stat">
            {!!item.stat && item.stat}
          </div>
        </>
      )
    } else {
      backgroundImage = (
        <BackgroundWordpressImage
          src={item.imageUrl}
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            backgroundSize: "contain",
          }}
        >
          {!!item.stat && item.stat}
        </BackgroundWordpressImage>
      )
    }

    return (
      <SwiperSlide key={shortid.generate()}>
        <div className="make-square">
          <div className="make-square-content">{backgroundImage}</div>
        </div>
        <div className="text-center">
          {!!item.headline && item.headline}
          {!!item.subheadline && item.subheadline}
          {!!item.body && item.body}
        </div>
      </SwiperSlide>
    )
  })

  const prevRef = useRef(null)
  const nextRef = useRef(null)

  const swiperNavDark = !(backgroundColorSlug === 'black'
    || backgroundColorSlug === 'dark'
    || backgroundColorSlug === 'burgundy'
  )

  const showSwiperScrollNav = (slides.length > slidesPerView)

  return (
    <div
      className={classNames(
        "component component-carousel alignfull",
        `bg-${backgroundColorSlug}`
      )}
      style={{
        backgroundColor: backgroundColor || "transparent",
        color: textColor || "inherit",
      }}
    >
      <div className="container">
        {title && (<h3 className="mazda-stories-carousel-title" style={{ color: textColor || "inherit" }}>{title}</h3>)}
        <div className="row">
          <div className="col-10 offset-1 col-md-12 offset-md-0">
            <div className="row">
              <div className="col-12 col-md-10 offset-md-1">
                <Swiper
                  breakpoints={{
                    768: {
                      centeredSlides: false,
                      grabCursor: true,
                      slidesPerView,
                      spaceBetween: 32,
                    },
                  }}
                  navigation={{
                    prevEl: prevRef?.current,
                    nextEl: nextRef?.current,
                  }}
                  observer
                  observeParents
                  onSwiper={(swiper) => {
                    // Delay execution for the refs to be defined
                    setTimeout(() => {
                      if (swiper.params?.navigation) {
                        // Override prevEl & nextEl now that refs are defined
                        // eslint-disable-next-line no-param-reassign
                        swiper.params.navigation.prevEl = prevRef.current
                        // eslint-disable-next-line no-param-reassign
                        swiper.params.navigation.nextEl = nextRef.current

                        // Re-init navigation
                        swiper.navigation.destroy()
                        swiper.navigation.init()
                        swiper.navigation.update()
                      }
                    })
                  }}
                  pagination
                  preventClicks={false}
                  preventClicksPropagation={false}
                  scrollbar={{ draggable: true }}
                  spaceBetween={0}
                  slidesPerView={1}
                  slideToClickedSlide={true}
                >
                  {slides}

                  {showSwiperScrollNav && (
                    <SwiperNavButtons dark={swiperNavDark} ref={{ prevRef, nextRef}}/>
                  )}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
