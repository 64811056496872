/**
 * External Dependencies
 */
import React from "react"
import { domToReact } from "html-react-parser"

/**
 * Internal Dependencies
 */
import { colorToSlug } from "../colors"
import BoxOut from "../../components/BoxOut"

export const isBoxOut = domNode =>
  domNode.name === "div" &&
  domNode.attribs?.class?.startsWith("wp-block-mazda-stories-box-out")

export const parseBoxOut = (domNode, options) => (
  <BoxOut
    background={domNode.attribs["data-background"]}
    backgroundColor={domNode.attribs["data-background-color"]}
    backgroundColorSlug={colorToSlug(domNode.attribs["data-background-color"])}
    imgDesktop={domNode.attribs["data-image-url"]}
    imgMobile={domNode.attribs["data-image-url"]}
    mediaLayout={domNode.attribs["data-media-layout"]}
    overlay={domNode.attribs["data-overlay"] === "true"}
    textColor={domNode.attribs["data-text-color"]}
    textLayout={domNode.attribs["data-text-layout"]}
    video={domNode.attribs["data-video"]}
    // headline={domToReact([domNode.children?.[1]])}
    innerContent={domToReact(domNode.children)}
    additionalClassNames={domNode.attribs["class"].replace('wp-block-mazda-stories-box-out alignfull', '')}
    lottieUrl={domNode.attribs["data-lottie-url"]}
    // lottieUrl='https://assets1.lottiefiles.com/private_files/lf30_G8vbVI.json'
  />
)
