/**
 * External Dependencies
 */
import React from "react"
import { domToReact } from "html-react-parser"

/**
 * Internal Dependencies
 */
import Spot from "../../components/Spot"

export const isSpot = domNode =>
  domNode.name === "div" &&
  domNode.attribs?.class?.startsWith("wp-block-mazda-stories-spot")

export const parseSpot = (domNode, options) => (
  <Spot
    imageBeforeUrl={domNode.attribs["data-image-before-url"]}
    imageAfterUrl={domNode.attribs["data-image-after-url"]}
    innerContent={domToReact(
      domNode.children?.[0]?.children?.[0]?.children?.[1]?.children
    )}
  />
)
