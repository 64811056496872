/**
 * External Dependencies
 */
import React from 'react'

/**
 * Internal Dependencies
 */
import WordpressImage from './WordpressImage'

export default function Spot({ imageBeforeUrl, imageAfterUrl, innerContent }) {
  return (
    <div className="component component-spot alignfull">
      <div className="container">
        <div className="row">
          <div className="col-md-3" data-testid="col">
            <div className="row">
              <div className="col-6 offset-3 col-md-12 offset-md-0" data-testid="image-before">
                {!!imageBeforeUrl && (
                  <WordpressImage
                    effect="fadeInUp"
                    className="component-spot--img--before"
                    src={imageBeforeUrl}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6" data-testid="inner-content">
            {innerContent}
          </div>
          <div className="col-md-3" data-testid="col">
            <div className="row">
              <div className="col-6 offset-3 col-md-12 offset-md-0" data-testid="image-after">
                {!!imageAfterUrl && (
                  <WordpressImage
                    effect="fadeInUp"
                    className="component-spot--img--after"
                    src={imageAfterUrl}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
