/* eslint-disable import/prefer-default-export */

/**
 * Keep these in sync with the WordPress colours in the WordPress repo.
 *
 * See /functions.php::mazda_stories_setup() in mazda-stories-wordpress
 */

export const colorToSlug = hex => {
  const colors = [
    {
      name: "Dark",
      slug: "dark",
      hex: "#222222",
    },
    {
      name: "Mid-grey",
      slug: "mid-grey",
      hex: "#AAAAAA",
    },
    {
      name: "Light-grey",
      slug: "light-grey",
      hex: "#F1F1F1",
    },
    {
      name: "Putty",
      slug: "putty",
      hex: "#E5DED4",
    },
    {
      name: "Burgundy",
      slug: "burgundy",
      hex: "#350C13",
    },
    {
      name: "White",
      slug: "white",
      hex: "#FFFFFF",
    },
  ]

  const color = colors.find(x => x.hex === hex)

  if (color) {
    return color.slug
  }

  return "none"
}
