/**
 * External Dependencies
 */
import React from "react"
import { domToReact } from "html-react-parser"

/**
 * Internal Dependencies
 */
import OverlappingColumns from "../../components/OverlappingColumns"

export const isOverlappingColumns = domNode =>
  domNode.name === "div" &&
  domNode.attribs?.class?.startsWith(
    "wp-block-mazda-stories-overlapping-columns"
  )

export const parseOverlappingColumns = (domNode, options) => (
  <OverlappingColumns
    imageSmallUrl={domNode.children?.[3]?.attribs["data-image-small-url"]}
    imageLargeUrl={domNode.children?.[3]?.attribs["data-image-large-url"]}
    headline={domToReact([domNode.children?.[1]])}
    bodyText={domToReact([domNode.children?.[5]])}
  />
)
