/**
 * External Dependencies
 */
import React from "react"
import { domToReact } from "html-react-parser"

/**
 * Internal Dependencies
 */
import { colorToSlug } from "../colors"
import ArticleHeader from "../../components/ArticleHeader"

export const isArticleHeader = domNode =>
  domNode.name === "div" &&
  domNode.attribs?.class?.startsWith("wp-block-mazda-stories-article-header")

export const parseArticleHeader = (domNode, options) => (
  <ArticleHeader
    background={domNode.attribs["data-background"]}
    backgroundColor={domNode.attribs["data-background-color"]}
    backgroundColorSlug={colorToSlug(domNode.attribs["data-background-color"])}
    imgDesktop={domNode.attribs["data-image-url"]}
    imgMobile={domNode.attribs["data-mobile-image-url"]}
    video={domNode.attribs["data-video"]}
    overlay={domNode.attribs["data-overlay"] === "true"}
    overline={domToReact([domNode.children?.[1]])}
    headline={domToReact([domNode.children?.[3]])}
    subheadline={domToReact([domNode.children?.[5]])}
    additionalClassNames={domNode.attribs["class"].replace('wp-block-mazda-stories-article-header alignfull', '')}
  />
)
