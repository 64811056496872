/**
 * External Dependencies
 */
 import React from "react"

import arrowLeft from "../../content/assets/arrows/arrow-left.svg"
import arrowLeftDark from "../../content/assets/arrows/arrow-left-dark.svg"
import arrowRight from "../../content/assets/arrows/arrow-right.svg"
import arrowRightDark from "../../content/assets/arrows/arrow-right-dark.svg"

const SwiperNavButtons = React.forwardRef(({ dark }, { prevRef, nextRef }) => {
  const wrapperClass = [
    'swiper-navigation',
  ]

  if (dark) {
    wrapperClass.push('dark')
  }

  return (
    <div className={wrapperClass.join(' ')}>
      <div className="btn-group" role="group" aria-label="Swiper Navigation">
        <div
          className="btn btn-sm swiper-button-nav swiper-left-button"
        >
          <img src={(dark) ? arrowLeftDark : arrowLeft} ref={prevRef} alt="<" />
        </div>
        <div
          className="btn btn-sm swiper-button-nav swiper-right-button"
        >
          <img src={(dark) ? arrowRightDark : arrowRight} ref={nextRef} alt=">" />
        </div>
      </div>
    </div>
  )
});

export default SwiperNavButtons
