/* eslint-disable react-hooks/exhaustive-deps */
/**
 * External Dependencies
 */
import React, { useEffect, useRef } from "react"
import classNames from "classnames"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

/**
 * Internal Dependencies
 */
import Animation from "./Animation"
import BackgroundWordpressImage from "./BackgroundWordpressImage"
import WordpressImage from "./WordpressImage"

export default function BoxOut({
  backgroundColor,
  backgroundColorSlug,
  imgDesktop,
  imgMobile,
  overlay,
  textColor,
  textLayout,
  mediaLayout,
  headline,
  innerContent,
  video,
  additionalClassNames,
  lottieUrl,
}) {
  const timelineRef = useRef(null)
  const pinnedRef = useRef(null)
  const bodyRef = useRef(null)

  const extraClasses = (additionalClassNames !== undefined) ? additionalClassNames.trim() : ''
  const noMarginBottom = extraClasses.includes('no-margin-bottom')
  const noMarginTop = extraClasses.includes('no-margin-top')

  const Timeline = ({ children }) => {
    useEffect(() => {
      if (timelineRef.current == null) return
      if (pinnedRef.current == null) return
      if (bodyRef.current == null) return

      if (typeof window !== `undefined`) {
        gsap.registerPlugin(ScrollTrigger)
        gsap.core.globals("ScrollTrigger", ScrollTrigger)
        ScrollTrigger.saveStyles(".mobile, .desktop")
      }

      ScrollTrigger.matchMedia({
        // Only for tablet & larger
        // eslint-disable-next-line func-names
        "(min-width: 768px)": function () {
          // pin project01
          ScrollTrigger.create({
            trigger: pinnedRef.current,
            start: "top 64px",
            end: "bottom 100%",
            endTrigger: bodyRef.current,
            pin: true,
            pinSpacing: false,
            // markers: true,
            id: "pin-box-out",
            // onEnter: ({ progress, direction, isActive }) => {
            //   // eslint-disable-next-line no-console
            //   console.log(pinnedRef.current)
            // },
          })
        },
      })

      // Now make the component kill the gsap instance when unmounted
      // https://greensock.com/forums/topic/25526-react-passing-array-of-refs-to-timeline/
      // return () => {
      //   pinnedRef?.current?.kill()
      //   bodyRef?.current?.kill()
      // }
    }, [pinnedRef, bodyRef])

    return <div ref={timelineRef}>{children}</div>
  }

  const imageCol = (
    <div
      className={classNames(
        "component-box-out--column",
        "component-box-out--column--img",
        "col-md-4",
        {
          "offset-md-1 order-md-2": textLayout === "left",
          "offset-md-2": textLayout !== "left",
          // fudge for /explore/mazda-mx-5-30th-anniversary-tour/
          // where it has background image and text right,
          // and we want to hide that div so it doesn't push the text on the
          // right down on larger desktop browser sizes
          "offset-md-2 has-background-media-text-right": (mediaLayout === "background" && textLayout !== "left"),
        }
      )}
    >
      {!!imgDesktop && !lottieUrl && (
        <div ref={pinnedRef}>
          <WordpressImage effect="fadeIn" src={imgDesktop} alt="" />
        </div>
      )}
      { !!lottieUrl && (
        <div ref={pinnedRef}>
          <Animation path={lottieUrl} />
        </div>
      )}
    </div>
  )

  const textCol = (
    <div
      className={classNames({
        "component-box-out--column": true,
        "component-box-out--column--text": true,
        "col-md-3": true,
        "offset-md-2 order-md-1":
          mediaLayout === "foreground" && textLayout === "left",
        "offset-md-1": mediaLayout === "foreground" && textLayout === "right",
        "offset-md-9": mediaLayout === "background" && textLayout === "right",
      })}
      ref={bodyRef}
    >
      {innerContent}
    </div>
  )

  return (
    <BackgroundWordpressImage
      backgroundColor={backgroundColor}
      backgroundPosition="fixed"
      src={mediaLayout === "background" ? imgDesktop : null}
      className={classNames(
        "component",
        "component-box-out",
        "alignfull",
        `bg-${backgroundColorSlug}`,
        {
          "has-background-media": mediaLayout === "background",
          "has-video": video.length,
          "no-margin-bottom": (noMarginBottom),
          "no-margin-top": (noMarginTop),
        }
      )}
    >
      <Timeline>
        {!!video?.length && (
          <video
            className="background-video"
            autoPlay
            playsInline
            loop
            muted
            src={video}
          />
        )}
        {!!overlay && <div className="overlay" />}
        <div
          style={{
            zIndex: 2,
            color: textColor,
          }}
        >
          <div className="container">
            <div className="row">
              {imageCol}
              {textCol}
            </div>
          </div>
        </div>
      </Timeline>
    </BackgroundWordpressImage>
  )
}
