/* eslint-disable consistent-return */
/**
 * External Dependencies
 */
import parse from "html-react-parser"

/**
 * Internal Dependencies
 */
import { isArticleHeader, parseArticleHeader } from "./article-header"
import { isBoxOut, parseBoxOut } from "./box-out"
import { isCarousel, parseCarousel } from "./carousel"
import { isCTA, parseCTA } from "./cta"
import { isFullscreenPanel, parseFullscreenPanel } from "./fullscreen-panel"
import { isGridLayout, parseGridLayout } from "./grid-layout"
import {
  isOverlappingColumns,
  parseOverlappingColumns,
} from "./overlapping-columns"
import { isSpot, parseSpot } from "./spot"
import { isStack, parseStack } from "./stack"

const options = {
  trim: true,
  replace: domNode => {
    // Whitespace nodes (line returns) have no attribs
    if (!domNode.attribs) {
      return
    }

    // eslint-disable-next-line no-console
    // console.dir(domNode, { depth: 1 })

    // Article Header
    if (isArticleHeader(domNode)) {
      return parseArticleHeader(domNode, options)
    }

    // Box Out
    if (isBoxOut(domNode)) {
      return parseBoxOut(domNode, options)
    }

    // Carousel
    if (isCarousel(domNode)) {
      return parseCarousel(domNode, options)
    }

    // CTA
    if (isCTA(domNode)) {
      return parseCTA(domNode, options)
    }

    // Fullscreen Panel
    if (isFullscreenPanel(domNode)) {
      return parseFullscreenPanel(domNode, options)
    }

    // Grid Layout
    if (isGridLayout(domNode)) {
      return parseGridLayout(domNode, options)
    }

    // Overlapping Columns
    if (isOverlappingColumns(domNode)) {
      return parseOverlappingColumns(domNode, options)
    }

    // Spot
    if (isSpot(domNode)) {
      return parseSpot(domNode, options)
    }

    // Stack
    if (isStack(domNode)) {
      return parseStack(domNode, options)
    }

    // Doesn't look like one of our components
    return domNode
  },
}

export default content => parse(content, options)
